// standard property
// 'scrollbar-color'
// https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-color
// currently (June 2020) Firefox only
// https://caniuse.com/#feat=mdn-css_properties_scrollbar-color
html {
  scrollbar-color: darken($bf-gray-dark, 5%) $bf-dark-extra;
}

// these features are not standard, and blink and webkit only
// '::-webkit-scrollbar'
// https://developer.mozilla.org/en-US/docs/Web/CSS/::-webkit-scrollbar

::-webkit-scrollbar {
  width: 1.5rem;
  height: 1.5rem;
}

::-webkit-scrollbar-track {
  background-color: $bf-dark-extra;
}

::-webkit-scrollbar-thumb {
  background-color: darken($bf-gray-dark, 5%);
}

::-webkit-scrollbar-thumb:hover {
  background-color: lighten($bf-gray-dark, 10%);
}
