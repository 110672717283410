// these are global stylest tha that ovveride all the "light" styles

// import dark scrollbars utility module
@import 'utility-global-dark-scrollbars';

// the basics: background and texts
html {
  color: $bf-light;
}

body {
  background-color: lighten($bf-dark-extra, 7.5%);
}

// anchors
a {
  color: lighten($bf-blue, 20%);

  &:hover,
  &:focus {
    color: lighten($bf-blue-light, 20%);
  }
}

// typography elements that need tiny adjustments
mark {
  background-color: darken($bf-yellow, 10%);
}

ins {
  background-color: darken($bf-yellow-light, 45%);
}

// forms

[type='email'],
[type='number'],
[type='password'],
[type='tel'],
[type='search'],
[type='text'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='time'],
[type='url'],
[type='range'],
input[list],
select,
select:not([size]):not([multiple]),
textarea {
  background-color: $bf-dark-extra;
  border-color: $bf-black;
  color: $bf-gray-light;

  &:active,
  &:focus {
    background-color: $bf-dark-extra;
    border-color: lighten($bf-blue, 10%);
  }
}

[type='email'],
[type='number'],
[type='password'],
[type='tel'],
[type='search'],
[type='text'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='time'],
[type='url'],
input[list],
select,
select:not([size]):not([multiple]),
textarea {
  &:active,
  &:focus {
    box-shadow: 0 0 0 3px rgba(3, 102, 214, 0.5);
  }
}

.bf-focused {
  border-color: lighten($bf-blue, 10%);
  box-shadow: 0 0 0 3px rgba(3, 102, 214, 0.5) !important;
}

.bf-invalid,
.bf-invalid:focus {
  color: lighten($bf-red, 7.5%) !important;
  border-color: lighten($bf-red, 7.5%) !important;
}

select:not([size]):not([multiple]) {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23f0f0f0' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
}

// IE9 only hack
// https://gist.github.com/marcop135/cf5163ab23d1cb208248b955c990b2ea#ie-9-only
@media screen and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
  // hide custom arrow
  select:not([size]):not([multiple]),
  // RTL
  *[dir="rtl"] select:not([size]):not([multiple]),
  select[dir="rtl"]:not([size]):not([multiple]) {
    background-image: none;
  }
}

// range

// all modern browsers but not IE
@supports (appearance: none) {
  // override above declaration
  [type='range'] {
    background-color: transparent;

    &:focus {
      background-color: transparent;
    }
  }

  // darker background
  // the rulesets must be separated
  [type='range']::-webkit-slider-runnable-track {
    background-color: $bf-dark-extra;
  }

  [type='range']::-moz-range-track {
    background-color: $bf-dark-extra;
  }
}

input::placeholder,
textarea::placeholder {
  color: $bf-gray;
}

button,
[type='button'],
[type='reset'],
[type='submit'],
.bf-btn,
button.bf-disabled,
[type='button'].bf-disabled,
[type='reset'].bf-disabled,
[type='submit'].bf-disabled,
.bf-btn.bf-disabled {
  background: $bf-gray-light;

  &:hover,
  &:active,
  &:focus {
    background: darken($bf-gray-light, 10%);
  }

  &:active,
  &:focus {
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.45);
  }
}

button,
[type='button'],
[type='reset'],
[type='submit'],
.bf-btn {
  &:active,
  &:focus {
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.45);
  }
}

.bf-btn--primary.bf-disabled,
.bf-btn.bf-btn--primary {
  background: $bf-blue;

  &:hover,
  &:active,
  &:focus {
    background: darken($bf-blue, 5%);
  }

  &:active,
  &:focus {
    box-shadow: 0 0 0 3px rgba(0, 86, 179, 0.75);
  }
}

// focus
a:focus,
input:focus {
  outline-color: darken(orange, 15%);
}

:focus-visible {
  outline-color: darken(orange, 15%);
}

// tables

.bf-table th,
.bf-table td {
  border-color: $bf-gray-dark;
}

.bf-table--zebra tbody tr:nth-child(odd),
.bf-table-responsive--zebra tbody tr:nth-child(odd) {
  background-color: $bf-gray-dark-extra;
}
